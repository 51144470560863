@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,600;1,300&display=swap");

* {
  outline: none !important;
  box-sizing: border-box;
  margin: 0px;
  font-family: "Montserrat", sans-serif !important;
  padding: 0px;
  list-style: none;
  text-decoration: none;
}

/* --------------------   Variables  -------------------- */

:root {
  --ggsorange: #e1523e;
  --ggsblack: #12110c;
  --ggsgrey: #63666a;
}



/* --------------------  NavBar -------------------- */

.NavBar-Container {
  position: fixed;
  opacity: 0.9;
  width: 100%;
  background-color: white;
  height: 80px;
  display: flex;
  justify-content: center;
  z-index: 10;
  align-items: center;
}
.Left-navlinks-container {
  display: flex;
  margin-right: 80px;
}
.Right-navlinks-container {
  display: flex;
  margin-left: 80px;
}

.Nav-Logo {
  font-size: 1.2rem;
  letter-spacing: 0.5rem;
  text-decoration: none;
  z-index: 10;
  cursor: pointer;
  font-weight: 700;
}
.LenguageButton {
  margin-left: 10px;
  background-color: white;
  color: black;
  border: none;
  width: 30px;
  height: 15px;
  padding-top: 1px;
  padding-bottom: 1px;
  transition: 0.9s;
  margin-top: 5px;
  border-radius: 5px;
  font-size: 0.7rem;
  cursor: pointer;
}

.LenguageButton:hover {
  background-color: var(--ggsorange);
  color: white;
}
.Links-navbar {
  margin-right: 30px;
  color: white;
  font-weight: lighter;

  font-size: 1.2rem;
  cursor: pointer;
  transition: 0.9s;
}

.Links-navbar:hover {
  color: var(--ggsorange);
}

/* -------------------- Video Background --------------------  */

#videoBG {
  z-index: -1;
  width: 100vw !important;
  display: flex;
  justify-content: center;
}
/*--------------------  Clock Section --------------------  */


.Clock-section-container {
  display: flex;
  align-items: center;
  background-image: url("./assets/Shapes.svg");
  background-size: cover;
  justify-content: center;
}

.Container-clock-img img {
  max-width: 400px;

}

.Clock-h1 {
  font-size: 4rem;
  font-weight: bold;
  color: var(--ggsorange);
}
.Clock-info-container{
  width: 500px;
  height: 100%;
}
.Clock-info-container h2 {
  font-size: 1.7rem;
  margin-right: 30px;
  font-weight: bold;
  color: black;
}

.Btn-clock {
  width: 100px;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;

  border: none;
  background-color: black;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  transition: 0.9s;
}


.Btn-clock:hover {
  background-color: var(--ggsorange);
}

/* -------------------- About Us Section --------------------  */

.About-section-container {
  height: 100vh;
  max-width: 2700px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.Info-square-aboutsection {
  height: 450px;
  width: 85%;
  background-color: black;
  margin-bottom: 120px;
  justify-content: space-around;
  align-items: center;
  display: flex;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.Title-aboutus-container {
  width: 600px;
  color: white;
  text-align: center;
}

.Aboutus-principal-title {
  font-size: 2rem;
  text-align: left;
}

.Accountant-articule-about {
  max-width: 350px;
  height: 80%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.Accountant-fact {
  height: 100px;
  margin: 11px 15px 15px 12px;
  width: 90px;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  flex-direction: column;
  font-size: 1.9rem;
}

.About-Us-span {
  font-size: 1.2rem;
  font-weight: normal;
}

.factone {
  justify-content: center;
  align-items: flex-start;
}

.How-container-aboutus {
  background-color: var(--ggsorange);
  position: absolute;
  width: 370px;
  border-radius: 5px;
  height: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35%;
}

.Articules-about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 1200px) {
  .How-container-aboutus {
    margin-top: 50%;
    width: 320px;
    height: 220px;
  }
}

.How-container-aboutus h2 {
  color: white;
  font-weight: bold;
  margin-top: 20px;
  font-size: 1.5rem;
}

.How-container-aboutus li {
  color: white;
  margin: 1px;
  font-size: 1rem;
}

.Orange-square-span {
  color: white;
}

/* -------------------- Carousel --------------------  */

.Clients-container {
  height: 100%;
  padding-top: 80px !important;
  width: 100%;
  overflow: hidden;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

}

.Clients-carousel {
  width: 200px;
}
.Clients-container {
  background-color: black;
}
.Clients-container h1 {
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.logotext-image {
  width: 200px !important;
}
.sc-AxmLO {
  background: var(--ggsblack) !important;
}

.logotext-image {
  width: 75%;
}
.rec.rec-arrow {
  border-radius: 0px;
}

.rec-arrow {
  background-color: var(--ggsblack) !important;
  transition: 0.9s !important;
  margin-bottom: 100px !important;
}
.rec-arrow:hover {
  background-color: var(--ggsorange) !important;
}

.rec-arrow:focus {
  background-color: var(--ggsorange) !important;
}

.nutriben {
  margin-top: 70px;
}
.decore {
  margin-top: 40px;
}

/* -------------------- Project Section -------------------- */
.Principal-container-projects{
  background-color: black;
}
.Principal-container-article{
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px;
  grid-template-rows: 200px 200px 200px 200px ;
  justify-content: center;
}

.Video-one-saul{
  width: 300px;
  grid-column: 1/2;
  cursor: pointer;
  grid-row: 1/2;

}

.Video-one-saul img{
  width: 300px;
}

.Video-two-sportline{
  grid-column: 3/5;
  width: 300px;
  cursor: pointer;

  grid-row:1/2 ;
}
.Video-two-sportline img{
  width: 300px;
}

.Video-three-anita{
  grid-row: 1/2;
  grid-column: 5/6;
  cursor: pointer;

}

.Video-four-bk{
  grid-row: 2/5;
  width: 300px;
  grid-column: 3/5;
  cursor: pointer;

}
.Video-four-bk img{
  width: 300px;
}

.Video-five-klasico{
  grid-row: 4/5;
  width: 300px;
  cursor: pointer;

  height: 170px;

  grid-column: 5/7;
}
.Video-five-klasico img{
  width:300px;

}


.Video-six-arboleada{
  grid-column: 3/5;
  width: 300px;
  grid-row: 4/6;
  cursor: pointer;

}

.Video-six-arboleada img{
  width: 300px;

}
.Video-seven-nutriben-red{
  grid-row: 3/4;
  grid-column: 1/2;
  cursor: pointer;


}

.Video-eight-arboleada-kids{
  grid-row: 4/5;
  cursor: pointer;

  
}

.Video-eight-nutriben-blue{
  grid-column: 5/6;
  cursor: pointer;

  grid-row: 3/4;
}

.Video-eight-nutriben-blue img{
  width: 300px;
}

/* -------------------- Modal Sections  -------------------- */
.Modal{
  background-color: black;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100vh;
  overflow-x:hidden !important;
  overflow-y: hidden !important;
  z-index: 99 !important;
}
.Youtebe-video{
  
  display: flex;
  z-index: 99 !important;

  justify-content: center;
}


.Btn-closemodal{
  width: 200px;
  z-index: 99 !important;

  align-self: center;
  margin-top: 10px;
  border-radius: 5px;
  border: none;
  height: 35px;
  background-color: white;
  color: black;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.9s;
}

.Btn-closemodal:hover{
  background-color: var(--ggsorange);
  color: black;
}


/* -------------------- New Project Section -------------------- */

.Project-landing-container {
  text-align: center;
  background-color: black;
  display: flex;  
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.Project-title {
  color: white;
  font-size: 2.9rem;
}

.Project-subtitle-text {
  color: white;
  font-size: 1.9rem;
}

.Portfolio {
  color: white;
  font-size: 1rem;
  border-bottom: 2px solid var(--ggsorange);
}

/* -------------------- Modal Is Open -------------------- */

.services-img {
  width: 300px;
}

/* -------------------- CASES  -------------------- */

.cases {
  width: 100%;
  height: 100%;
}
.cases-navigation {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
}

.row {
  display: flex;
}

.case {
  position: relative;
  width: 450px;
  overflow: hidden;
}
.case-details {
  width: 33.3333vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  color: black;
  color: white;

  position: relative;
}
.Services-span {
  margin-top: 156px;
  font-size: 1.2rem !important;
  opacity: 0.8;
  font-weight: 600;
}

.h3-services {
  font-size: 1.3rem !important;
  line-height: 3.4rem;
  z-index: 999;
  width: 85%;
  margin-top: 16px;
  letter-spacing: 0;
}

.case-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  transition: 0.4s cubic-bezier(0.6, -0.05, 0.1, 0.99);
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0)),
    url(./assets/production.jpg);
  background-size: cover;
}
.case-two {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0)),
    url(./assets/programming.jpg);
}
.case-five {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0)),
    url(./assets/marketing.jpg);
}
.case-four {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0)),
    url(./assets/socialmedia.jpg);
}
.case-three {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0)),
    url(./assets/media.jpg);
}

.case-six {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0)),
    url(./assets/graphigdesign.jpg);
}



/* -------------------- Contact section -------------------- */

.Container-images {
  display: flex;
}
.Contact-container-section {
  position: relative;
  display: flex;

  margin-top: 200px;
  bottom: 155px;
  justify-content: center;
}
.Contact-form-container {
  display: flex;
  justify-content: center;
  border-radius: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: 900px;
}
.Contact-title {
  font-size: 3rem;
  margin-bottom: 20px;
}
.Contact-infor-form {
  margin-top: 100px;
}

.Typewriter-contact-container {
  position: relative;
  display: flex;
  justify-content: center;
  bottom: 150px;
}
.Typewriter-contact-container img {
  width: 450px;
}

.Contact-Form-info-container {
  margin-top: 20px;
  display: flex;

  flex-direction: column;
}

.Contact-Form-info-container h1 {
  width: 300px;
  margin-top: 20px;
  text-align: center;
}

.Form-h2 {
  margin-left: 20px;
}
.Form-container {
  display: flex;
  margin-left: 20px;
}
.Input-form {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid var(--ggsorange);
  margin-top: 30px;
  font-size: 2rem;
}

.Text-tarea {
  margin-top: 10px;
  width: 457px;
  font-size: 2rem;
  height: 100px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid var(--ggsorange);
}

.btn-info {
  width: 120px;
  height: 45px;
  border: none;
  background-color: black;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  transition: 0.9s;
  margin-top: 10px;
}

.btn-info:hover {
  background-color: var(--ggsorange);
}

/* -------------------- Scroll -------------------- */

.Color-scroll {
  font-weight: bold;
}

/* -------------------- Footer --------------------  */

.Principal-footer-container{
  z-index: 0;
}
.Section-footer {
  height: 100%;
  padding-bottom: 10px;
  padding-top: 20px;
  background-color: black;
  display: flex;
  justify-content: space-around;
  z-index: 0;
}

.Footer-title {
  color: white;
}
.ggmediosfooter {
  letter-spacing: 0.5rem;
  font-size: 1.3rem;
}

.Mid-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  z-index: 0;
}
.Logo-footer {
  width: 80%;
  height: 80%;
  z-index: 0;
}

.footer-container {
  width: 300px;
  
}
.Left-footer {
  margin-left: 100px;
  margin-top: 19px;
}

.pin {
  width: 30px;
  height: 30px;
}

.Direction {
  color: white;
  width: 350px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Li-footer {
  display: flex;
  align-items: center;
  color: white;
  margin-top: 20px;
  cursor: pointer;
}

.Socialmedia {
  margin-left: 4px;
}

.Copy-footer {
  color: white;

  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Copy-text {
  margin-left: 20px;
}

.Right-footer {
  display: flex;
  margin-left: 100px;
  flex-direction: column;
  margin-top: 20px;
}
.Footer-navbar {
  display: flex;
  flex-direction: column;
}
.Links-navbar-footer {
  color: white;
  margin-top: 20px;
}

/*  -------------------- New Filters -------------------- */

.Filter-carousel-container{
  background-color: #000;
  justify-content: center;
  display: flex;
}

.Filters-center{
  border-radius: 40px !important;
  height: 450px;

}
.carousel-filters{
  width: 350px !important;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 40px;
  align-content: center;
  align-items: center;
}

.side-filters{
  margin-top: 100px;
  margin-left: 40px;
  margin-right: 40px;
}

/* ------------------------- About Us ------------------------- */

@media only screen and (max-width: 850px) {
  .About-section-container {
    align-content: center;
    height: 100%;
  }
  .Info-square-aboutsection {
    flex-direction: column;
    width: 300px;
    height: 100%;
    margin-top: 60px;
  }

  .Aboutus-principal-title {
    width: 200px;
    font-size: 1rem;
  }
  .Title-aboutus-container {
    width: 200px;
    margin-top: 50px;
  }
  .How-container-aboutus {
    margin-top: 500px;
  }
  .Accountant-articule-about {
    padding-bottom: 20px;
  }
  
  /* More filters */

  .side-filters{
    display: none;
  }
  /* Contact Us */

  .Form-container{
   
    justify-content: center;
  }
   
  /* --------  Filters ------------- */

  .left-filters{
    margin: 0px !important;
    width: 200px;
    margin-top: 0px;
    border-radius: 50px !important;
  }

 

  .right-filters{
    margin: 0px;
    width: 200px;

  }

  


  .Title-filters-principal{

    display: flex;
    flex-direction: column;
  }

  .Filters-text{
    font-size: 1rem;
    width: 330px !important;
  }
  /* ------------------------- Projects View ------------------------- */
  .Principal-container-article{
    display: grid;
    grid-template-columns: 300px;
    grid-template-rows: 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px;
    justify-content: center;
  }

  .Video-two-sportline{
    grid-column: 1/2;
    width: 300px;
    cursor: pointer;
  }
  
  .Video-one-saul{
    width: 300px;
    grid-column: 1/1;
    grid-row: 7/8;
    cursor: pointer;
  
  }
  .Video-four-bk{
    width: 300px;
    grid-column: 1/2;
    cursor: pointer;
    grid-row: 10/11;
  
  }
  .Video-two-sportline{
    grid-column: 1/2;
    width: 300px;
    cursor: pointer;
    grid-row:1/2 ;
    margin-top: 20px;

  }

  .Video-three-anita{
    grid-column: 1/1;
    grid-row: 2/3;
    cursor: pointer;
    margin-top: 20px;   


  
  }

  .Video-five-klasico{
    grid-row: 6/7;
    width: 300px;
    cursor: pointer;
    grid-column: 1/2;
  }

  .Video-six-arboleada{
    grid-column: 1/2;
    width: 300px;
    margin-top: 20px;

    grid-row: 4/6;
    cursor: pointer;
  
  }

  .Video-seven-nutriben-red{
    grid-row: 3/4;
    grid-column: 1/2;
    margin-top: 20px;
    display: none;
    cursor: pointer;
  
  
  }
  .Video-eight-arboleada-kids{
    grid-column: 1/2;
    margin-top: 20px;
    grid-row: 5/6;

    cursor: pointer;
  }

  .Video-eight-nutriben-blue{
    grid-column: 1/1;
    cursor: pointer;
    margin-top: 20px;
    grid-row: 9/10;
    margin-bottom: 10px ;
  }
  
  /* ------------------------- Youtube Videos ------------------------- */

  .Youtubeid{
    width: 300px !;
  }
  /* ------------------------- Contact Us ------------------------- */

  .Typewriter-contact-container {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .Typewriter-contact-container img {
    width: 300px;
  }
  .Contact-container-section {
    text-align: center;
  }
  .Contact-Form-info-container {
    width: 320px;
  }
  .Input-form {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid var(--ggsorange);
    margin-top: 30px;
    font-size: 1.3rem;
    width: 250px;
  }
  .Form-container{
    margin: 0px;
  }
  .Text-tarea {
    margin-top: 10px;
    width: 250px;
    font-size: 1.3rem;
    height: 100px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid var(--ggsorange);
  }

  .Form-h2 {
    text-align: center;
    margin: 0px;
  }

  .Contactbtn-container {
    display: flex;
    justify-content: center;
  }  

    /* ------------------------- Header ------------------------- */

    .Links-navbar{
      margin-right: 0px;
    }

  /* ------------------------- FOOTER ------------------------- */
  .Section-footer {
    background-color: black;
    flex-direction: column;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    max-width: 2000px !important;
  }

  .Copy-text {
    margin: 0px;
    width: 300px;
    text-align: center;
  }

  .Right-footer {
    margin: 0px;
    text-align: center;
  }

  .Left-footer {
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .Socialmedia {
    margin: 20px;
  }
  .linkedin {
    margin-right: 15px;
  }

  
  /* ------------------------- Clock Section ------------------------- */

  .Clock-section-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    background-image: none !important;
  }
  
  .Clock-h1 {
    font-size: 2.8rem;
  }
  .Clock-info-container h2 {
    margin-right: 0px;
    font-size: 1.3rem;
  }
  .Container-clock-img img {
    width: 300px;
    margin: 0px;
  }
  .Clock-info-container {
    color: white;
    width: 300px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding-bottom: 20px;
  }
  .Btn-clock{
    margin-top: 10px;
  }

    /* ------------------------- Cases ------------------------- */
 
   .case{
     width: 50%;
     display: flex;
     flex-wrap: wrap;
   }
   .container-fluid{
     display: flex;
     width: 100%;
     margin-top: 100px;
     justify-content: center;
   }
   .row{
     flex-wrap: wrap;
     display: flex;
     width: 100%;
    
   }

   /*------------------------------Video BACK --------------------- */

   #videoBG{
     margin-bottom: 380px;
   }
   
}


/* ----------------------- Tablets -------------------------------- */


@media only screen and (min-width: 1024px) and  (max-width: 1350px){


  /*------------------ Title ------------------- */
  .Project-landing-container{
    height: 50vh;
  }
  .Principal-container-projects{
    grid-template-columns: 200px 200px 200px;
  }
  .Video-three-anita{
    width: 200px;
    grid-column: 3/4;
  }
  .Video-three-anita img{
    width: 250px;
  }
  .Video-one-saul{
    width: 200px;
    grid-column: 1/3;
    margin-left: 30px;
  }

  .Video-one-saul img{
    width: 250px;
  }
  .Video-eight-nutriben-blue{
    width: 250px; 
  }
  .Video-eight-nutriben-blue{
    width: 100px;
  }
  .Video-eight-nutriben-blue img{
    width: 230px;
  }
  .Video-five-klasico{
    width: 230px;
  }
  .Video-five-klasico img{
    width: 230px;
  }
  .Video-two-sportline{
    width: 230px ;
  }
  .Video-two-sportline img{
    width: 250px !important;
    height: 100px;
  }
  .Video-eight-nutriben-blue{
    width: 250px;
    grid-column: 4/5;
    margin-left: 130px;
  }
  .Video-eight-nutriben-blue img{
    width: 250px;
  }
  .Video-five-klasico{
    width: 250px;
    margin-left: 130px;
    grid-column: 4/5;


  }

  .Video-five-klasico img{
    width: 250px;
  }


  .Video-eight-arboleada-kids {
    width: 200px;
  }

  .Video-eight-arboleada-kids img{
    width: 200px;
  }
  .Video-four-bk{
    width: 200px;
    grid-column: 4/5;
    margin-left: 130px;
    grid-row: 1/2;
  }
  .Video-four-bk img{
    width: 250px;
    grid-column: 5/6;
    grid-row: 1/2;


  }

  .Video-two-sportline{
    width: 200px;
    grid-row: 3/4;
  }

  .Video-two-sportline img{
    width: 300px;
  }

  .Video-six-arboleada{
    width: 250px;
  }
  .Video-six-arboleada img{
    width: 250px;
  }

  .Video-seven-nutriben-red{
    width: 250px;
    margin-left: 30px;

  }
  .Video-seven-nutriben-red img{
    width: 250px;


  }
  .Video-eight-arboleada-kids{
    width: 250px;
  }
  .Video-eight-arboleada-kids img{
    width: 250px;
    margin-left: 30px;
  }

  /* ------------------- Clock ------------------------------- */
 
  .Clock-h1{
    font-size: 3rem;
    margin-top: 30px;

  }
  .Container-clock-img img{
    width: 300px;
  }
  .Perfect-time{
    padding-top: 20px;
    padding-bottom: 25px;
  }
  .Btn-clock{
    margin-bottom: 20px;
  }
}


/* ...  Filters Section ... */

.Root-filters-container{
  width: 100% !important;  
}

.Filters-card{
  height: 400px;  
  border-radius: 20px;
}


.Filters-text{
  font-size: 0.9rem;
  width: 550px ;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.left-filters{
  margin-left: 40% ;
}

.right-filters{
  margin-right: 180px;
}
.Title-filters-principal{
  display: flex;
  justify-content: center;
}

.Title-filters-section{
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  font-size: 2.5rem !important  ;
  text-align: center;
  color: white;
  font-weight: bold;
  justify-content: center;
}


.Aumented{
  color:#e1523e !important;
  margin-left: 7px;
  font-size: 2.5rem;
}

/* Responsive max width 375*/


@media only screen and (max-width: 375px){
  #videoBG {
    z-index: -1;
    width: 100vw !important;
    display: flex;
    width: 100vw;
    justify-content: center;
    margin-bottom: 455px;
  }

}


/* Responsive 0 to 764*/

@media only screen and (min-width:376px) and (max-width: 765px){

  #videoBG {
    z-index: -1;
    width: 100vw !important;
    display: flex;
    width: 100vw;
    justify-content: center;
    margin-bottom: 450px;
  }

}


/* Responsive 765 to 999*/

@media only screen and (min-width: 765px) and (max-width: 999px){

  #videoBG {
    z-index: -1;
    width: 100vw !important;
    display: flex;
    width: 100vw;
    justify-content: center;
    margin-bottom: 410px;
  }

}


/* Responsive 1024 */

@media only screen and (max-width: 1437px) and (min-width: 1000px){

  #videoBG {
    z-index: -1;
    width: 100vw !important;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
  }
  .How-container-aboutus{
    margin-left: 10px;
  }
}


/* Responsive 1440 */

@media only screen and (max-width: 2600px) and (min-width: 1358px){



#videoBG {
  z-index: -1;
  width: 100vw !important;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.How-container-aboutus{
  margin-left: 50px;
  margin-top: 450px;
}
}


@media only screen and (min-width: 1024px) and  (max-width: 1250px){
  #videoBG {
    z-index: -1;
    width: 100vw !important;
    display: flex;
    width: 100vw;
    justify-content: center;
    margin-bottom: 455px;
  }
}


.Btn-clock{
  margin-top: 20px !important;
}


.Perfect-time{
  padding-top: 20px;
  padding-bottom: 10px;
}


.Info-container-home{
  z-index: 0 !important;
}



/* New Changes CSS  */



#Services{
  justify-content: center;
  display: flex;
}

.ScrollContainer{
  border: 1px solid red;
}